<template>
  <v-container fluid>
    <V-row>
      <v-col md="12">
        <SettingItem
          @loadData="loadData"
          collection="review"
          :items="review"
          :itemHeader="reviewItemHeader"
        />
      </v-col>
    </V-row>
  </v-container>
</template>

<script>
import SettingItem from "./SettingItem/index";
import ApiService from "@/services/api.service";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    SettingItem,
  },
  data() {
    return {
      review: [],
      reviewItemHeader: [
        { text: "full name", value: "full_name" },
        { text: "email", value: "email" },
        { text: "title", value: "title" },
        { text: "message", value: "message" },
      ],
    };
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        this.fetchData();
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchData() {
      try {
        const data = await ApiService.get(
          "items/technical_support?fields=id,title,email,full_name,message"
        );
        this.review = data.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchData(undefined);
  },
};
</script>

<style></style>
